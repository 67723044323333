import store from '@/store/index'
import config from '@/config';
import {getLodop} from '@/utils/LodopFuncs'
import ViewUI from 'view-design';
import {delUsbPrint,getUsbOrderData, getUsbCancelOrderData} from '@/api/order'
var that;
var Socket = {
    websocket: "",
    getUntreated: "",
    timer: "",
    client: "",
    lodop:"",
    ok_print:false,
    printData:{},
    init: function (getUntreated) {
        that = this;
        setTimeout(() => {
            // that.CheckIsInstall()
        }, 5000);
        that.getUntreated = getUntreated;
        if (typeof (WebSocket) === "undefined") {
            console.log("您的浏览器不支持socket"); return;
        }
        console.log(config.wsUrl)
        that.websocket = new WebSocket(config.wsUrl);
        that.websocket.onmessage = this.message;
        that.websocket.onopen = this.open;
        that.websocket.onerror = this.error;
        that.websocket.onclose = this.close;
        this.setPrint()
    },
    getPrintData(){
        that.printData = JSON.parse(localStorage.getItem('printNameForm'))
    },
    
    setPrint(){
		if (navigator.platform.indexOf("Win") < 0) {
			console.log('不是windows系统。禁止调用')
			return;
		}
        that.getPrintData()
        try{
            that.lodop = getLodop();
            let counter = that.lodop.GET_PRINTER_COUNT();
            let printNameList = [];
            console.log('设置的打印机：'+that.printData.wired_type)
            for (let i = 0; i < counter; i++) {
                //将打印机存入printList数组中
                printNameList.push(that.lodop.GET_PRINTER_NAME(i));
                if (that.lodop.GET_PRINTER_NAME(i).indexOf(that.printData.wired_type)!=-1) {
                    console.log('选中：'+i)
                    that.ok_print = true;
                    that.lodop.SET_PRINTER_INDEX(i)
                }
            }
            localStorage.setItem('printNameList',JSON.stringify(printNameList))
            console.log(printNameList)
        } catch (e){
            console.log('检测不到USB打印机驱动')
            console.log('error', e)
        }
        
    },
    // fromOrderToPrint(id){
    //     getOrderPrintContent({'no_popup':1, 'order_id':id, 'machine_width': that.printData.machine_width}).then(res=>{
    //         that.startPrint(id, res.data.content); 
    //         // let array = res.data.content
    //         // array.forEach(item => {
    //         //     that.startPrint(item); 
    //         // });
    //     })
    // },
    getUsbOrderData(data){
        if (navigator.platform.indexOf("Win") < 0) {
			console.log('不是windows系统。禁止调用');return;
		}
        if (typeof that.printData.isable == 'undefined' || that.printData.isable == 0 || that.printData.isable == "") {
            console.log("未开启有线打印机");return
        }
        data.no_popup = 1
        getUsbOrderData(data).then(res=>{
            that.startPrint(data.order_id, res.data.content); 
        })
    },
    getUsbCancelOrderData(data){
        if (navigator.platform.indexOf("Win") < 0) {
			console.log('不是windows系统。禁止调用');return;
		}
        if (typeof that.printData.isable == 'undefined' || that.printData.isable == 0 || that.printData.isable == "") {
            console.log("未开启有线打印机");return
        }
        data.no_popup = 1
        getUsbCancelOrderData(data).then(res=>{
            that.startPrint(data.order_id, res.data.content); 
        })
    },
    startPrint(id, content){
        
		if (navigator.platform.indexOf("Win") < 0) {
			console.log('不是windows系统。禁止调用');return;
		}
        if (typeof that.printData.isable == 'undefined' || that.printData.isable == 0 || that.printData.isable == "") {
            delUsbPrint({'order_id':id, 'no_popup':1}).then(res=>{
            })
            console.log("未开启有线打印机");return
        }
        that.setPrint();
        if (that.ok_print) {
            let width = that.printData.machine_width == 1? 800 : 580
            width = 800
            for(let i =0;i<that.printData.num; i++){
                that.lodop.PRINT_INIT("");	
                that.lodop.SET_PRINT_PAGESIZE(3, `${width}`,0,"");  
                that.lodop.SET_PRINT_STYLE("FontName","隶书");
                that.lodop.ADD_PRINT_HTM('1mm','1mm',`${width - 2}mm`,'100mm',content+"<div style='height:40px;width:5px'></div>")
                that.lodop.PRINT();
            }
        }
    },

    open: function () {
        if (that.timer != "") {
            clearInterval(that.timer);
            that.timer = "";
        }
        console.log("连接成功" + store.state.userInfo.data_id)
        that.client = Math.floor(Math.random() * (99999 - 10000)) + 10000;
        that.websocket.send(JSON.stringify({ 'type': 1, 'data': store.state.userInfo.data_id, 'client': that.client }))
        // getOrderPrintContent({'order_id':361}).then(res=>{
        //     that.startPrint(res.data);
        // })

    },

    message: function (e) {
        let json_data = JSON.parse(e.data);
        console.log(json_data)
        if (json_data['type'] == 1 && json_data['data'] == "ok") {
            console.log("登录成功")
            return;
        }
        if (json_data['type'] == "1003" && json_data['data'] == store.state.userInfo.data_id) {
            // console.log("收到新订单")
            that.getUsbOrderData({order_id:json_data.order_id,type:1});
        }else if(json_data['type'] == "1002" && json_data['data'] == store.state.userInfo.data_id){
            that.getUsbOrderData({order_id:json_data.cash_order_id,type:2});
        } else if(json_data['type'] == "2001" && json_data['data'] == store.state.userInfo.data_id){
            that.getUsbCancelOrderData({order_id:json_data.order_id});
        }
        
        if ( (json_data['type'] == 2  || json_data['type'] == 1002 ) &&  json_data['data'] == store.state.userInfo.data_id) {
            setTimeout(() => {
                typeof that.getUntreated == "function" && that.getUntreated();
            }, 2000);
            var url = config.apiUrl + "/upload/mp3/order.mp3";
            var mp3 = new Audio(url);
            mp3.play() 
        }
        
    },

    // CheckIsInstall: function () {
    //     console.log('打印机 打印！！！  打印！！！');
    //     try {
    //         var LODOP = getLodop()
    //         if (LODOP.VERSION) {
    //             if (LODOP.CVERSION)
    //                 alert("当前有WEB打印服务C-Lodop可用!\n C-Lodop版本:" + LODOP.CVERSION + "(内含Lodop" + LODOP.VERSION + ")");
    //             else
    //                 alert("本机已成功安装了Lodop控件！\n 版本号:" + LODOP.VERSION);
    //         }
    //     } catch (err) {
    //         console.log(err)
    //     }
    // },

    error: function () {
        console.log("链接失败")
    },

    close: function () {
        that.websocket.send(JSON.stringify({ 'type': 3, 'data': store.state.userInfo.data_id, 'client': that.client }))
        console.log("链接断开，5秒钟后尝试重新链接")
        if (that.timer == "") {
            that.timer = setInterval(function () {
                that.init(that.getUntreated);
            }, 5000)
        }
    }
}

export default Socket;