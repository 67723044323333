<template>
  <div class="son-page">
      <div @click="backBtn" class="back_box">
          <div class="pointer"><Icon size="24" type="ios-arrow-back" /></div>
          <div class="ml10">微信</div>
      </div>
     <div class="content_box">
       <div v-if="loading_status" class="loding_box">
          <Spin fix>
              <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
              <div>Loading</div>
          </Spin>
        </div>
        <span v-if="$store.state.store_mode == 1">
          <Tabs v-model="index" @on-click="changeTab">
            <TabPane label="小程序配置" name="1"></TabPane>
            <TabPane label="微信支付" name="2"></TabPane>
            <TabPane label="小程序消息订阅" name="4"></TabPane>
            <TabPane label="小程序上传" name="3"></TabPane>
          </Tabs>
          <span v-if="index=='1'">
            <Alert type="error" v-if="formData.app_id">
                温馨提示：
                <template slot="desc">
                      <div>登录小程序后台，选择开发-开发管理-开发设置-扫普通链接二维码打开小程序</div>
                      <table class="set-form">
                        <tr>
                          <td @click="copy($store.state.pay_url.url.tsapi_url +  formData.app_id)">堂食码：{{$store.state.pay_url.url.tsapi_url +  formData.app_id }}</td>
                          <td @click="copy('pages/ordering/ordering')">小程序路径：pages/ordering/ordering</td>
                        </tr>
                        <tr>
                          <td @click="copy($store.state.pay_url.url.goodsapi_url +  formData.app_id)">商品码：{{$store.state.pay_url.url.goodsapi_url +  formData.app_id }}</td>
                          <td @click="copy('pages/ordering/ordering')">小程序路径：pages/ordering/ordering</td>
                        </tr>
                        <tr>
                          <td @click="copy($store.state.pay_url.url.faceapi_url +  formData.app_id)">面对面支付码：{{$store.state.pay_url.url.faceapi_url +  formData.app_id }}</td>
                          <td @click="copy('pages/facepayment/facepayment')">小程序路径：pages/facepayment/facepayment</td>
                        </tr>
                        <tr>
                          <td  @click="copy($store.state.pay_url.url.coverapi_url +  formData.app_id)">会员绑定码：{{$store.state.pay_url.url.coverapi_url +  formData.app_id }}</td>
                          <td  @click="copy('pages/index/index')">小程序路径：pages/index/index</td>
                        </tr>
                      </table>
                </template>
            </Alert>
            <Form  label-position="left" :label-width="100">
                <FormItem label="appid：">
                    <Input v-model="formData.app_id" style="width:300px" placeholder="请输入appid"></Input>
                </FormItem>
                <FormItem label="secret：">
                    <Input v-model="formData.secret" style="width:300px" placeholder="请输入secret"></Input>
                </FormItem>
            </Form>

          </span>
          
          <Form v-if="index=='2'" label-position="left" :label-width="140">
              <FormItem label="商户支付选择：">
                <RadioGroup v-model="formData.type" :true-value="1" :false-value="2">
                    <Radio :label="1">服务商支付</Radio>
                    <Radio :label="2">普通商户支付</Radio>
                </RadioGroup>
              </FormItem>
              <span v-if="formData.type==1||!formData.type">
                <FormItem label="服务商appid：">
                    <Input v-model="formData.service_appid" style="width:300px" placeholder="请输入服务商appid"></Input>
                </FormItem>
                <FormItem label="服务商证书序列号：">
                    <Input v-model="formData.serial_number" style="width:300px" placeholder="请输入证书序列号"></Input>
                </FormItem>
                <FormItem label="服务商户号：">
                    <Input v-model="formData.merchant_no" style="width:300px" placeholder="请输入商户号"></Input>
                </FormItem>
                <FormItem label="子商户号：">
                    <Input v-model="formData.sub_merchant_no" style="width:300px" placeholder="请输入子商户号"></Input>
                </FormItem>
                <FormItem label="服务商v2支付密钥：">
                    <Input v-model="formData.merchant_key_v2" style="width:300px" placeholder="请输入服务商v2支付密钥"></Input>
                </FormItem>
                <FormItem label="服务商v3支付密钥：">
                    <Input v-model="formData.merchant_key" style="width:300px" placeholder="请输入服务商v3支付密钥"></Input>
                </FormItem>
                <FormItem label="服务商支付证书：">
                  <div style="display:flex">
                      <div>
                          <Upload
                          ref="upload"
                          :show-upload-list="false"
                          :format="['pem']"
                          :max-size="2048"
                          :headers="header"
                          :data="{type:1}"
                          :on-format-error="handleFormatError"
                          :on-success="uploadImgSuccess"
                          :on-progress="handleProgress"
                          multiple
                          :action="uploads">
                          <div v-if="formData.cert">
                            <Button>已上传</Button>
                            <span style="margin-left:10px;font-size:12px;color:#999">只支持上传key证书</span>
                          </div>
                          <div v-else>
                            <Button>上传</Button>
                            <span style="margin-left:10px;font-size:12px;color:#999">只支持上传key证书</span>
                          </div>
                          </Upload>
                      </div>
                  </div>
                </FormItem>
              </span>
              <span v-if="formData.type==2">
                <FormItem label="商户号：">
                    <Input v-model="formData.sub_merchant_no" style="width:300px" placeholder="请输入商户号"></Input>
                </FormItem>
                <FormItem label="v2支付秘钥：">
                    <div class="flex_text">
                      <div><Input v-model="formData.ordinary_key_v2" style="width:300px" placeholder="请输入v2支付秘钥"></Input></div>
                    </div>
                </FormItem>
                <FormItem label="v3支付秘钥：">
                    <div class="flex_text">
                      <div><Input v-model="formData.ordinary_key" style="width:300px" placeholder="请输入v3支付秘钥"></Input></div>
                    </div>
                </FormItem>
                <FormItem label="支付身份证书：">
                    <RadioGroup v-model="formData.cert_type" :true-value="1" :false-value="2">
                        <Radio :label="1">微信公钥</Radio>
                        <Radio :label="2">平台证书</Radio>
                    </RadioGroup>
                </FormItem>
                <template v-if="formData.cert_type == 1 || !formData.cert_type">
                    <FormItem label="公钥ID：">
                        <Input v-model="formData.ordinary_serial_no" style="width:300px" placeholder="请输入公钥ID"></Input>
                    </FormItem>
                    <FormItem label="微信支付公钥证书：">
                        <div style="display:flex">
                            <div>
                                <Upload
                                ref="upload"
                                :show-upload-list="false"
                                :format="['pem']"
                                :max-size="2048"
                                :headers="header"
                                :on-format-error="handleFormatError"
                                :data="{type:2}"
                                :on-success="uploadImgSuccess2"
                                :on-progress="handleProgress"
                                multiple
                                :action="uploads">
                                <Button v-if="formData.ordinary_serial_cert">已上传</Button>
                                <Button v-else>上传</Button>
                                </Upload>
                            </div>
                        </div>
                    </FormItem>
                    <FormItem label="支付证书序列号：">
                        <Input v-model="formData.pay_number" style="width:300px" placeholder="请输入支付证书序列号"></Input>
                    </FormItem>
                    <FormItem label="支付证书：">
                        <div style="display:flex">
                            <div>
                                <Upload
                                ref="upload"
                                :show-upload-list="false"
                                :format="['pem']"
                                :max-size="2048"
                                :headers="header"
                                :on-format-error="handleFormatError"
                                :data="{type:2}"
                                :on-success="uploadImgSuccess3"
                                :on-progress="handleProgress"
                                multiple
                                :action="uploads">
                                <Button v-if="formData.pay_cert">已上传</Button>
                                <Button v-else>上传</Button>
                                </Upload>
                            </div>
                        </div>
                    </FormItem>
                </template>
                <template v-if="formData.cert_type == 2">
                    <FormItem label="v3证书序列号：">
                        <Input v-model="formData.ordinary_serial_number" style="width:300px" placeholder="请输入证书序列号"></Input>
                    </FormItem>
                    <FormItem label="v3支付证书：">
                        <div style="display:flex">
                            <div>
                                <Upload
                                ref="upload"
                                :show-upload-list="false"
                                :format="['pem']"
                                :max-size="2048"
                                :headers="header"
                                :on-format-error="handleFormatError"
                                :data="{type:2}"
                                :on-success="uploadImgSuccess1"
                                :on-progress="handleProgress"
                                multiple
                                :action="uploads">
                                <Button v-if="formData.ordinary_cert">已上传</Button>
                                <Button v-else>上传</Button>
                                </Upload>
                            </div>
                        </div>
                    </FormItem>
                </template>
              </span>
          </Form>
            <div v-if="index=='3'">
              <div class="content_box"  v-show="showView" style="align-items: center;justify-content: center;display: flex;overflow: hidden">
                  <div  v-show="loading_status1" >
                      <img src="../../../../assets/loading.gif" height="100" width="100"/>
                      <div style="text-align: center">加载中</div>
                  </div>
                  <iframe v-show="!loading_status1" id="wx_iframe" :src="auth_url" style="width:100%;height:700px;border:0;" scrolling="no"/>
              </div>
              <span v-show="!showView">
                <div class="wx-update-applets" style="position: relative;">
                    <div @click="show_wx_update_list" class="wx-one-applets">
                        <div style="line-height:2">
                            {{version}}
                        </div>
                        <div class="wx-version">
                            当前版本
                        </div>
                    </div>
                    <div style="border:none;cursor:unset" class="wx-one-applets">
                        <div style="line-height: 2">
                            {{historList.length!=0?historList[0].user_version:'暂无版本'}}
                        </div>
                        <div class="wx-version">
                            最新版本
                        </div>
                    </div>
                    <div style="flex:2">
                    </div>
                    <div @click="show_wx_func" class="information_txt">配置信息</div>
                    <div style="width:300px;">
                        <div>
                          <div class="font_text" v-if="ApproveStatus.approve_status==-1">未提交</div>
                          <div class="font_text" v-if="ApproveStatus.approve_status==0">审核成功</div>
                          <div class="font_text" @click="reasonModal = true" style="cursor: pointer;" v-if="ApproveStatus.approve_status==1">审核失败 <Icon type="ios-help-circle-outline" /></div>
                          <div class="font_text" v-if="ApproveStatus.approve_status==2">审核中</div>
                          <div class="font_text" v-if="ApproveStatus.approve_status==3">已撤回</div>
                          <div class="font_text" v-if="ApproveStatus.approve_status==4">审核延后</div>
                        </div>
                        <div v-if="ApproveStatus.approve_status||ApproveStatus.approve_status==0" class="Btns_box">
                          <div class="wx-update-button" @click="updateBtn(1)" v-if="ApproveStatus.approve_status==-2||ApproveStatus.approve_status==1"><Icon type="ios-cloud-upload-outline" /> 上传代码</div>
                          <div class="wx-update-button" @click="updateBtn(2)" v-if="ApproveStatus.approve_status==-1||ApproveStatus.approve_status==3"><Icon type="ios-cloud-upload-outline" /> 提交审核</div>
                          <div class="wx-update-button" @click="updateBtn(3)" v-if="ApproveStatus.approve_status==0"><Icon type="ios-cloud-upload-outline" /> 发布</div>
                        </div>
                    </div>

                </div>
                <div style="margin:20px 0 40px">
                    <div style="font-size: 16px"><Icon type="md-time" class="history-time"/>官方版本维护记录</div>
                    <div v-for="(item,index) of historList" :key="index" class="history-list">
                        <div ><span style="color:#1B2742">版本号：</span>{{item.user_version}}</div>
                        <div ><span style="color:#1B2742">发布时间：</span>{{item.create_time}}</div>
                        <div>{{item.user_desc}}</div>
                    </div>
                </div>
              </span>
            </div>

          <Form v-if="index=='4'" label-position="left" :label-width="120">
              <Alert type="error" >
                  温馨提示：
                  <template slot="desc">
                        <div>请先在小程序后台增加小程序类目：生活服务 > 百货/超市/便利店</div>
                  </template>
              </Alert>
              <FormItem label="订单消息提醒：">
                <i-switch @on-change="switchBtn" v-model="status" :true-value="1" :false-value="0"/>
                
              <span v-if="status" style="margin-left:20px;color:#006eff;cursor: pointer;" @click="delTemplate" >删除</span>
              </FormItem>
          </Form>
        </span>

        <span v-else>
          <Tabs value="1" @on-click="changeTabChain">
            <TabPane label="微信支付" name="1"></TabPane>
            <TabPane label="小程序二维码" name="2"></TabPane>
          </Tabs>
          <Form v-if="Chainindex=='1'" label-position="left" :label-width="140">
                <FormItem label="子商户号：">
                    <Input v-model="formData.sub_merchant_no" style="width:300px" placeholder="请输入子商户号"></Input>
                </FormItem>
          </Form>
          <Form v-if="Chainindex=='2'" label-position="left" :label-width="100">
              <FormItem label="小程序码：">
                <img v-if="new_applet_code" style="width:200px;height:200px" :src="new_applet_code" alt="">
                <div style="margin-top:10px;width:200px;display: flex;justify-content: center;"><Button @click="shuacodeBtn">刷新小程序码</Button></div>
              </FormItem>
          </Form>
        </span>

      </div>
      <div v-if="index!=3" class="bottom_btn">
          <Button v-if="$store.state.store_mode == 1" @click="addBtn" class="btn">{{index=="3"?'上传':'保存'}}</Button>
          <Button v-else-if="$store.state.store_mode == 2 && Chainindex==1" @click="split_account" class="btn">保存</Button>
      </div>

      <Modal
              :mask-closable="false"
              v-model="modalcode"
              :title="codetxt"
              @on-ok="ok"
              @on-cancel="cancel">
          <div v-if="showLogin" class="showLoginBox">
              <Col class="demo-spin-col" span="8">
                  <Spin fix>
                      <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                      <div>上传中</div>
                  </Spin>
              </Col>
          </div>
          <div v-else class="imgBox">
              <img style="width:100%" :src="codePic" alt="">
              <div style="color:red">{{codetxt}}</div>
          </div>
      </Modal>


      <Modal
              width="600"
              :mask-closable="false"
              v-model="show_wx"
              title="微信配置"
              @on-cancel="cancel_wx">
          <div >
                <Form style="height:500px;overflow:auto;padding-bottom:70px"  label-position="right" :label-width="180">
                    <FormItem label="小程序名称：">
                        <Input v-model="historData.mini_name" style="width:300px" placeholder="请输入小程序名称"></Input>
                    </FormItem>
                    <FormItem label="appid：">
                        <Input disabled v-model="historData.appid" style="width:300px" placeholder="请输入appid"></Input>
                    </FormItem>
                    <FormItem label="手机号：">
                        <Input v-model="historData.phone" style="width:300px" placeholder="请输入手机号"></Input>
                    </FormItem>
                    <!-- <FormItem label="域名：">
                        <div class="flex_text">
                          <div><Input v-model="historData.domain" style="width:260px" placeholder="请输入域名"></Input></div>
                          <Icon @click="RefreshBtn" style="font-size:20px;font-weight:600;margin-left:20px;cursor: pointer;" type="ios-refresh" />
                        </div>
                    </FormItem>
                    <FormItem label="request合法域名：">
                        <Input v-model="historData.request" style="width:300px" placeholder="以https://开头，可填写多个域名，用;分割域名"></Input>
                    </FormItem>
                    <FormItem label="socket合法域名：">
                        <Input v-model="historData.wss" style="width:300px" placeholder="以wss://开头，可填写多个域名，用;分割域名"></Input>
                    </FormItem>
                    <FormItem label="uploadFile合法域名：">
                        <Input v-model="historData.upload" style="width:300px" placeholder="以https://开头，可填写多个域名，用;分割域名"></Input>
                    </FormItem>
                    <FormItem label="downloadFile合法域名：">
                        <Input v-model="historData.download" style="width:300px" placeholder="以https://开头，可填写多个域名，用;分割域名"></Input>
                    </FormItem>
                    <FormItem label="udp合法域名：">
                        <Input v-model="historData.udp" style="width:300px" placeholder="以udp://开头，可填写多个域名，用;分割域名"></Input>
                    </FormItem>
                    <FormItem label="tcp合法域名：">
                        <Input v-model="historData.tcp" style="width:300px" placeholder="以tcp://开头，可填写多个域名，用;分割域名"></Input>
                    </FormItem> -->
                    <FormItem label="分类一：">
                        <Select @on-change="cateChange" v-model="historData.first_cate" style="width:200px">
                            <Option v-for="item in historData.cate1" :value="item.id" :key="item.id">{{ item.class }}</Option>
                        </Select>
                        <Button @click="reloadWxCate" type="warning" ghost style="margin-left:10px">刷新</Button >
                    </FormItem>
                    <FormItem label="分类二：">
                        <Select v-model="historData.second_cate" style="width:200px">
                            <Option v-for="item in historData.cate2" :value="item.id" :key="item.id">{{ item.class }}</Option>
                        </Select>
                    </FormItem>
                </Form>
          </div>
          <div slot="footer">
            <div class="model_bottom_box">
              <div>
                <Button v-if="historData.phone&&historData.appid" @click="privacyBtn" type="warning" ghost style="margin-left:10px">更新隐私协议</Button >
              </div>
              <div>
                <Button @click="cancel_wx">取消</Button>
                <Button @click="ok_wx" class="btn">确定</Button>
              </div>
            </div>
        </div>
      </Modal>
      <Modal
              id="update-history-list"
              :mask-closable="false"
              v-model="show_update_history"
              width="900"
              title="更新记录">

          <Table stripe style="margin-top:20px" :columns="update_history_column" :data="RecordList">
            <template slot-scope="{ index }" slot="index">
                <strong>{{ index+1 }}</strong>
            </template>
            <template slot-scope="{ row }" slot="status">
              <div v-if="row.status==-1">未提交</div>
              <div v-if="row.status==0">审核成功</div>
              <div v-if="row.status==1">审核被拒绝</div>
              <div v-if="row.status==2">审核中</div>
              <div v-if="row.status==3">已撤回</div>
              <div v-if="row.status==4">审核延后</div>
            </template>
            <template slot-scope="{ row }" slot="is_release">
              <div v-if="row.is_release==0">未发布</div>
              <div v-if="row.is_release==1">已发布</div>
            </template>
          </Table>
          <br/>
          <Page :total="total" :current="storePrams.page" style="text-align:right" show-elevator show-total @on-change="page_change" :page-size="storePrams.limit"/>
          <div slot="footer"></div>
      </Modal>

      <Modal
          v-model="reasonModal"
          title="失败原因"
          @on-ok="ok"
          @on-cancel="cancel">
          <div>
            {{ApproveStatus.reason?ApproveStatus.reason:'暂无信息~'}}
          </div>
      </Modal>

      <!-- 隐私协议弹框 -->
      <Modal
              width="400"
              :mask-closable="false"
              v-model="privacyModal"
              title="隐私协议">
          <div style="display: flex;justify-content: center;">
                <RadioGroup v-model="privacy_ver">
                    <Radio :label="1">已有现网隐私协议</Radio>
                    <Radio style="margin-left:30px" :label="2">尚无现网隐私协议</Radio>
                </RadioGroup>
          </div>
          <div slot="footer">
            <Button @click="ok_privacy" class="btn">确定</Button>
        </div>
      </Modal>
  </div>
</template>

<script>
import {api,appletscheck} from '@/api/code'
import {infoWechat,setWechat,subscribe,add_code,delWxTemplate, add_account,setAppWxPay, wxBindAuth,wxVersionList,getWxConf,reloadWxCate,setWxConf,getAuthStatus,uploadPrivate,getWxApproveStatus,uploadWxCode,releax,uploadApprove,approveRecord,updateDomainLocal} from '@/api/setting'
import config from '@/config';
import {getCookies, helperCopy} from '@/utils/helper'
export default {
  name: "password",
  data() {
    return {
      total:0,
      storePrams:{
        page:1,
        limit:6
      },
      show_wx:false,
      show_update_history:false,
      showView:true,
      privacyModal:false,
      privacy_ver:null,
      RecordList:[],
      version:'0.0.0',
      cityList: [
            {
                value: 'New York',
                label: 'New York'
            },
            {
                value: 'London',
                label: 'London'
            }
      ],
      ApproveStatus:{},
      header:{
          'hlzw-canyin-authori-zation':getCookies('hlzw_canyin_token')
      },
      update_history_column: [
          {
              title: '#',
              slot: 'index',
              width:60,
              align: 'center'
          },
          {
              title: '更新时间',
              key: 'created_at',
              align: 'center',
              width: 180
          },
          {
              title: 'APPID',
              key: 'appid',
              align: 'center',
              width: 180
          },
          {
              title: '版本号',
              key: 'version',
              align: 'center'
          },
          {
              title: '审核状态',
              slot: 'status',
              align: 'center'
          },
          {
              title: '是否发布',
              slot: 'is_release',
              align: 'center'
          },
          {
              title: '拒绝原因',
              key: 'reason',
              align: 'center'
      }],
      showLogin: false,
      auth_url:"",
      data6:[],
      butStatus:0,
      loading:true,
      reasonModal:false,
      loading_status:false,
      loading_status1:false,
      codePic:'',
      codetxt:'请扫码登录',
      timer:null,
      uploads: config.apiUrl + '/storeapi/apply/certUploads',
      index:'1',
      Chainindex:'1',
      status:0,
      picview:'',
      modalcode:false,
      new_applet_code:'',
      formData:{
        type:1,
        cert_type:1,
        account:'',
        split_ratio:'',
        name:'',
        service_appid:'',
        serial_number:'',
        sub_appid:'',
        app_id:'',
        secret:'',
        ordinary_serial_number:'',
        merchant_no:'',
        sub_merchant_no:'',
        merchant_key_v2:'',
        merchant_key:'',
        ordinary_key_v2:'',
        ordinary_key:'',
        cert:'',
        ordinary_cert:'',
        mini_name:'',
        domain:'',
        version:'',
        desc:'',
        content:'',
        ordinary_serial_no: '',
        ordinary_serial_cert: '',
        pay_number: '',
        pay_cert: '',
      },
      cate2:[],
      historList:[],
      historData:{}
    }
  },
  created() {
    this.formData.domain = document.domain
    this.infoWechat()
  },
  methods: {
    
    copy(url){
      helperCopy(url)
      this.$Message.success('复制成功');
    },
    Refresh_Status(){
      let that = this
      window.addEventListener('message', function(event) {
        if (event.data.code==200) {
          that.getAuthStatus()
        }
      });
    },
      show_wx_update_list(){
          this.approveRecord()
          this.show_update_history = true
      },
      show_wx_func(){
        this.show_wx = true
      },
      ok_wx(){
        let cate1 = this.historData.cate1
        let cate2 = this.historData.cate2
        cate1.forEach(item=>{
          if (item.id==this.historData.first_cate) {
            this.historData.first_cate_name = item.class
          }
        })
        cate2.forEach(item=>{
          if (item.id==this.historData.second_cate) {
            this.historData.second_cate_name = item.class
          }
        })
        setWxConf(this.historData).then(res=>{
          this.$Message.success(res.msg)
          this.show_wx = false
        }).catch(err=>{
          this.show_wx = true
          this.$Message.error(err.msg)
        })
      },
      RefreshBtn(){
        updateDomainLocal().then(res=>{
          this.getWxConf()
          this.$Message.success(res.msg)
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      },
      ok_privacy(){
        uploadPrivate({privacy_ver:this.privacy_ver}).then(res=>{
          this.$Message.success(res.msg)
          this.privacyModal = false
          getWxConf().then(res=>{
            this.privacy_ver = res.data.privacy_ver
          }).catch(err=>{})
        }).catch(err=>{
          this.$Message.error(err.msg)
        })
      },
      updateBtn(e){
        if (this.butStatus!=0) return
        if (!this.historData.mini_name||!this.historData.appid||!this.historData.phone||!this.historData.domain||!this.historData.request||!this.historData.wss||!this.historData.upload||!this.historData.download||!this.historData.udp||!this.historData.tcp||!this.historData.first_cate||!this.historData.second_cate){
          this.$Message.warning('请完善微信配置');
          this.show_wx = true
        }else{
          // this.loading_status = true
          this.butStatus  = 1;
          if (e==1) {
            // console.log('提交代码');
            uploadWxCode().then(res=>{
              // this.loading_status = false
               this.butStatus  = 0;
              this.getWxApproveStatus()
              this.$Message.success(res.msg)
            }).catch(err=>{
              // this.loading_status = false
               this.butStatus  = 0;
              this.$Message.error(err.msg)
            })
          }else if(e==2){
            // console.log('提交审核');
            uploadApprove().then(res=>{
              // this.loading_status = false
               this.butStatus  = 0;
              this.getWxApproveStatus()
              this.$Message.success(res.msg)
            }).catch(err=>{
              // this.loading_status = false
              this.butStatus  = 0;
              this.$Message.error(err.msg)
            })
          }else if(e==3){
            // console.log('发布');
            releax().then(res=>{
              // this.loading_status = false
              this.butStatus  = 0;
              this.getWxApproveStatus()
              this.$Message.success(res.msg)
            }).catch(err=>{
              // this.loading_status = false
              this.butStatus  = 0;
              this.$Message.error(err.msg)
            })
          }
        }
      },
      privacyBtn(){
        this.privacyModal = true
        // uploadPrivate().then(res=>{
        //   this.$Message.success(res.msg)
        // }).catch(err=>{
        //   this.$Message.error(err.msg)
        // })
      },
      cancel_wx(){
          this.show_wx = false
      },
      getWxBindUrl(){
          // this.loading_status1 = true
          let  _this = this
          wxBindAuth({appid:this.formData.app_id}).then(res=>{
              this.auth_url = res.data.url
              let iframe = document.getElementById("wx_iframe");
              iframe.onload = function(){
                // _this.loading_status1 = false
                  // _this.loading = false;
              }
          }).catch(err=>{

          // this.loading_status1 = false
              console.log(err)
          })
      },
    infoWechat(){
      // this.loading_status = true
      infoWechat().then(res=>{
          if ( res.data.length == 0){
            // this.loading_status = false
            return
          }
        this.picview = res.data.new_cert
        this.status = res.data.subscribe_status
        this.formData.service_appid = res.data.service_appid
        this.formData.ordinary_serial_number = res.data.ordinary_serial_number
        this.formData.ordinary_serial_no = res.data.ordinary_serial_no
        this.formData.ordinary_serial_cert = res.data.ordinary_serial_cert
        this.formData.pay_number = res.data.pay_number
        this.formData.pay_cert = res.data.pay_cert
        if (res.data.type) {
         this.formData.type = res.data.type 
        }
        this.formData.cert_type = res.data.cert_type
        this.formData.account = res.data.split.account
        this.formData.split_ratio = res.data.split.split_ratio
        this.formData.name = res.data.split.name
        this.formData.sub_appid = res.data.sub_appid
        this.formData.serial_number = res.data.serial_number
        this.formData.sub_merchant_no = res.data.sub_merchant_no
        this.formData.app_id = res.data.app_id
        this.formData.secret = res.data.secret
        this.formData.merchant_no = res.data.merchant_no
        this.formData.merchant_key_v2 = res.data.merchant_key_v2
        this.formData.merchant_key = res.data.merchant_key
        this.formData.cert = res.data.cert

        this.formData.ordinary_key_v2 = res.data.ordinary_key_v2
        this.formData.ordinary_key = res.data.ordinary_key
        this.formData.ordinary_cert = res.data.ordinary_cert
        this.formData.mini_name = res.data.mini_name
        if (res.data.domain) {
          this.formData.domain = res.data.domain
        }
        this.formData.version = res.data.version
        this.formData.desc = res.data.desc
        this.formData.content = res.data.content
        if (res.data.new_applet_code) {
          this.new_applet_code = res.data.new_applet_code 
        }else{
          this.new_applet_code = localStorage.getItem('new_applet_code')
        }
        this.getWxConf()
        this.getAuthStatus()
        // this.getWxBindUrl();
      })
    },
    switchBtn(e){
      subscribe({status:e}).then(res=>{
        this.$Message.success(res.msg)
      })
    },
    
    delTemplate(){
      this.$Modal.confirm({
          title: '提示',
          content: '<p>是否确定删除所有模板列表</p>',
          onOk: () => {
            delWxTemplate().then(res=>{
              this.$Message.success(res.msg)
                this.status = 0
            })
          },
          onCancel: () => {
          }
      });
      
    },
    wxVersionList(){
      wxVersionList().then(res=>{
        this.historList = res.data.list
        this.version = res.data.version
      })
    },
    getWxConf(){
      getWxConf().then(res=>{
        let url = location.origin
        let a = url.indexOf('/')
        let yuming = url.substring(a+2,url.length)
        this.historData = res.data
        if (!this.historData.domain) {
          this.historData.domain = yuming
        }
        if (!this.historData.request) {
          this.historData.request = 'https://'+yuming
        }
        if (!this.historData.wss) {
          this.historData.wss = 'wss://'+yuming
        }
        if (!this.historData.upload) {
          this.historData.upload = 'https://'+yuming
        }
        if (!this.historData.download) {
          this.historData.download = 'https://'+yuming
        }
        if (!this.historData.udp) {
          this.historData.udp = 'udp://'+yuming
        }
        if (!this.historData.tcp) {
          this.historData.tcp = 'tcp://'+yuming
        }
        this.cate2 = res.data.cate2
        if (this.historData.first_cate) {
          let data = []
          this.historData.cate2.forEach(item => {
            if (item.pid==this.historData.first_cate) {
              data.push(item)
            }
          });
          this.historData.cate2 = data
        }
        this.privacy_ver = res.data.privacy_ver
        // this.loading_status = false;
      }).catch(err=>{
        // this.loading_status = false;
      })
    },
    cateChange(){
      if (this.historData.first_cate) {
        let data = []
        this.cate2.forEach(item => {
          if (item.pid==this.historData.first_cate) {
            data.push(item)
          }
        });
        this.historData.cate2 = data
        this.historData.second_cate = ''
      }
    },
    reloadWxCate(){
      reloadWxCate({appid:this.historData.appid}).then(res=>{
        this.historData.first_cate = null
        this.historData.second_cate = null

        this.historData.cate1 = res.data.cate1
        this.cate2 = res.data.cate2
        this.$Message.success(res.msg)
        // this.historData.cate2 = res.data.cate2
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
    },
    getAuthStatus(){
      getAuthStatus({appid:this.formData.app_id}).then(res=>{
        // this.$Message.success(res.msg)
        this.getWxApproveStatus()
        this.showView = false
      }).catch(err=>{
        if (err.msg == 'appid不能为空') {
          if (this.$store.state.store_mode == 1) {
            this.$Message.error(err.msg)
            this.index = '1'
          }
        }else{
          this.getWxBindUrl()
          this.showView = true
        }
      })
    },
    getWxApproveStatus(){
      getWxApproveStatus().then(res=>{
        this.ApproveStatus = res.data
      })
    },
    changeTab(e){
      if (e==3) {
        this.wxVersionList()
        this.Refresh_Status()
      }
      this.infoWechat()
      this.index = e
    },
    changeTabChain(e){
      this.infoWechat()
      this.Chainindex = e
    },
    //文件上传类型错误
    handleFormatError () {
        this.$Message.warning('暂不支持上传此格式');
    },
    //编辑图片上传图片成功
    uploadImgSuccess(e){
        if (e.code==200) {
          // this.picview = e.data.path
          this.formData.cert = e.data.src
          this.$Message.destroy()
          this.$Message.success(e.msg);
        }else{
          this.$Message.destroy()
          this.$Message.error(e.msg);
        }
    },
    uploadImgSuccess1(e){
        if (e.code==200) {
          this.formData.ordinary_cert = e.data.src
          this.$Message.destroy()
          this.$Message.success(e.msg);
        }else{
          this.$Message.destroy()
          this.$Message.error(e.msg);
        }
    },
    uploadImgSuccess2(e){
        if (e.code==200) {
          this.formData.ordinary_serial_cert = e.data.src
          this.$Message.destroy()
          this.$Message.success(e.msg);
        }else{
          this.$Message.destroy()
          this.$Message.error(e.msg);
        }
    },
    uploadImgSuccess3(e){
        if (e.code==200) {
          this.formData.pay_cert = e.data.src
          this.$Message.destroy()
          this.$Message.success(e.msg);
        }else{
          this.$Message.destroy()
          this.$Message.error(e.msg);
        }
    },
    // 文件上传时
    handleProgress(){
        const msg = this.$Message.loading({
            content: '上传中...',
            duration: 0
        });
        setTimeout(msg, 3000);
    },
    cancel(){
      this.clearTime()
      this.showLogin = false
      this.codePic = ''
      this.modalcode = false
    },
    ok(){
      this.clearTime()
      this.showLogin = false
      this.codePic = ''
      this.modalcode = false
    },
    split_account(){
      // let data = {
      //   account:this.formData.account,
      //   split_ratio:this.formData.split_ratio,
      //   name:this.formData.name,
      // }
      let data
      if(this.formData.type==1){
        data = {
          // type:this.formData.type,
          sub_merchant_no:this.formData.sub_merchant_no
        }
      }else{
        data = {
            type:this.formData.type,
            cert_type:this.formData.cert_type,
            sub_merchant_no:this.formData.sub_merchant_no,
            ordinary_key:this.formData.ordinary_key,
            ordinary_serial_number:this.formData.ordinary_serial_number,
            ordinary_cert:this.formData.ordinary_cert,
            ordinary_serial_no: this.formData.ordinary_serial_no,
            ordinary_serial_cert: this.formData.ordinary_serial_cert,
            pay_number: this.formData.pay_number,
            pay_cert: this.formData.pay_cert,
        }
      }
      setAppWxPay(data).then(res=>{
        this.$Message.success(res.msg)
      }).catch(err=>{
        this.$Message.error(err.msg)
      })
      // add_account(data).then(res=>{
      //   this.$Message.success(res.msg)
      // }).catch(err=>{
      //   this.$Message.error(err.msg)
      // })
    },
    addBtn(){
      let Data = {}
      if (this.index==1) {
        Data = {
          app_id:this.formData.app_id,
          secret:this.formData.secret
        }
        this.$store.state.pay_url.wx_app_id = this.formData.app_id;
      }else if(this.index==2){
        if (this.formData.type==1) {
          Data = {
            type:this.formData.type,
            serial_number:this.formData.serial_number,
            service_appid:this.formData.service_appid,
            sub_merchant_no:this.formData.sub_merchant_no,
            merchant_no:this.formData.merchant_no,
            merchant_key:this.formData.merchant_key,
            merchant_key_v2:this.formData.merchant_key_v2,
            cert:this.formData.cert
          }
        }else{
          Data = {
            type:this.formData.type,
            cert_type:this.formData.cert_type,
            sub_merchant_no:this.formData.sub_merchant_no,
            ordinary_serial_number:this.formData.ordinary_serial_number,
            ordinary_key:this.formData.ordinary_key,
            ordinary_key_v2:this.formData.ordinary_key_v2,
            ordinary_cert:this.formData.ordinary_cert,
            ordinary_serial_no: this.formData.ordinary_serial_no,
            ordinary_serial_cert: this.formData.ordinary_serial_cert,
            pay_number: this.formData.pay_number,
            pay_cert: this.formData.pay_cert,
          }
        }
      }else if(this.index==3){
        Data = {
            mini_name:this.formData.mini_name,
            domain:this.formData.domain,
            version:this.formData.version,
            desc:this.formData.desc,
            content:this.formData.content,
          }
      }else if(this.index==4){
        console.log('订阅消息');
      }
      if (this.index==3) {
        setWechat(Data).then()
        let data = {
          url:this.formData.domain,
          appid:this.formData.app_id,
          name:this.formData.mini_name,
          authentication:0,
          version:this.formData.version,
          desc:this.formData.desc,
          uniacid:this.$store.state.userInfo.data_id,
          admin_id:this.$store.state.userInfo.admin_id,
          type:4
        }
        api(data).then(res=>{
          this.codePic = res.img
          this.time(res.id)
          this.timer = setInterval(() => {
            this.time(res.id)
          }, 10000);
        })
        this.modalcode = true
      }else{
        setWechat(Data).then(res=>{
          this.$Message.success(res.msg)
        }).catch(err=>{
          this.$Message.error({
            content:err.msg,
            duration:2
          })
        })
      }
    },
    shuacodeBtn(){
      add_code().then(res=>{
        this.$Message.success(res.msg)
        this.new_applet_code = res.data
      }).catch(err=>{
        this.$Message.error({
            content:err.msg,
            duration:2
          })
      })
    },
    time(id){
      let data = {
        id:id,
        uniacid:this.$store.state.userInfo.data_id,
        type:4,
        admin_id:this.$store.state.userInfo.admin_id
      }
      appletscheck(data).then(res=>{
        if(res.status==0){
          //未登录
          this.codetxt = '请扫码登录'
        }else if(res.status==1){
          //正在上传
          this.showLogin = true
        }else if(res.status==2){
          //上传成功
          this.showLogin = false
          this.new_applet_code = res.data
          localStorage.setItem('new_applet_code',res.data)
          // add_code({applet_code:res.data}).then(res=>{
          //   this.$Message.success(res.msg)
          // })
          this.codetxt = '小程序预览码'
          this.clearTime()
          this.modalcode = false
        }else if(res.status==3){
          //上传失败
          this.showLogin = false
          this.codetxt = '请扫码登录'
          this.clearTime()
          this.modalcode = false
          this.$Message.error(res.msg)
        }
      })
    },
    approveRecord(){
      approveRecord(this.storePrams).then(res=>{
        this.total = res.data.total
        this.RecordList = res.data.data
      })
    },
    page_change(e){
      this.storePrams.page = e
      this.approveRecord()
    },
    backBtn(){
        this.$emit('backview',0)
    },
    clearTime(){
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  beforeDestroy() {
    this.clearTime()
  },
}
</script>

<style scoped>
.loding_box{
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
}
.son-page{
  position: relative;
}
.imgBox{
  width: 300px;
  text-align: center;
  margin: 0 auto;
}
.Btns_box{
  display: flex;
  align-items: center;
  justify-content: center;
}
.back_box{
  width: 100%;
  cursor: pointer;
  background: #FFF;
  border-radius: 12px;
  display: flex;
  font-size: 18px;
  font-weight: 700;
  align-items: center;
  padding: 30px;
}
.content_box{
  width: 100%;
  height: 75vh;
  overflow: auto;
  margin-top: 30px;
  background: #FFF;
  border-radius: 12px;
  padding: 30px;
}
.demo-spin-icon-load{
    animation: ani-demo-spin 1s linear infinite;
}
.ivu-switch-checked{
  border-color: #FF761D;
  background-color: #FF761D;
}
.bottom_btn{
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 100px;
    border-top: 1px solid #eee;
    background: #FFF;
}
.flex_text{
  display: flex;
  align-items: center;
}
.error_txt{
    font-size: 14px;
    width: 200px;
    margin: 10px auto 0;
    color: #ff0000;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;			
    text-overflow: ellipsis;	
    display: -webkit-box;		
    -webkit-line-clamp: 2;		
    line-clamp: 2;					
    -webkit-box-orient: vertical;
}
.img_view_Box{
  width: 105px;
  height: 105px;
  border-radius: 5px;
  border: 1px solid #999;
  margin-right: 20px;
  overflow: hidden;
}
.img_view_Box img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.btn{
  background:#FF761D;
  color:#FFF;
  border-color: #FF761D;
}
.uplads_box{
    width: 105px;
    height: 105px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px dashed #A0A4BD;
    background: #F7F8FA;
    border-radius: 5px;
    cursor: pointer;
}
.textar{
    border: 1px solid #DCDEE2;
    outline: none;
    width: 300px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
}
.model_bottom_box{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom_uplad{
  display: flex;
  align-items: center;
}
.font_text{
    color: #FF7B22;
    font-size: 24px;
    margin-bottom: 10px;
  }
  .information_txt{
    position: absolute;
    top: 10px;
    right: 0;
    z-index: 9;
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
  }
</style>
<style>
  .ivu-tabs-nav .ivu-tabs-tab {
  display: inline-block;
  height: 100%;
  padding: 8px 16px;
  margin-right: 16px;
  box-sizing: border-box;
  cursor: pointer;
  text-decoration: none;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  transition: color .3s ease-in-out;
}
.ivu-btn-primary{
  background: #FF761D;
  border-color: #FF761D;
}
.ivu-tabs-nav .ivu-tabs-tab-active {
  color: #FF761D;
}
.ivu-tabs-nav .ivu-tabs-tab:hover {
  color: #FF761D;
}
.ivu-tabs-ink-bar {
  height: 2px;
  box-sizing: border-box;
  background-color: #FF761D;
  position: absolute;
  left: 0;
  bottom: 1px;
  z-index: 1;
  transition: transform .3s ease-in-out;
  transform-origin: 0 0;
}
.shop_flex{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.ivu-radio-checked .ivu-radio-inner{
    border-color: #FF761D;
}
.ivu-radio-inner:after{
    background-color: #FF761D;
}
.demo-spin-icon-load{
        animation: ani-demo-spin 1s linear infinite;
    }
    @keyframes ani-demo-spin {
        from { transform: rotate(0deg);}
        50%  { transform: rotate(180deg);}
        to   { transform: rotate(360deg);}
    }
    .demo-spin-col{
        height: 100px;
        position: relative;
    }
    .showLoginBox{
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .fath_box{
      position: relative;
    }
    .DW_code{
      position: absolute;
      top: 50px;
      left: 500px;
      text-align: center;
    }
    .DW_code img{
      width: 150px;
      height: 150px;
    }
    .wx-update-applets{
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size:30px;
        border-bottom: 1px solid #e9e9e9;
        padding-bottom: 10px;
    }
    .wx-one-applets{
        flex:1;
        cursor: pointer;
        border-right:1px solid #e9e9e9
    }
    .wx-update-button{
        width:150px;
        cursor: pointer;
        margin:0 auto;
        background: linear-gradient(to right, #FF761D, #FEBA62);
        color:#fff;
        border-radius:20px;
        padding:5px 0;
        font-size:16px
    }
    .wx-version{
        font-size:16px;color:#545F78
    }
    .wx-update-history{
        font-size:8px;
        position: absolute;
        cursor: pointer;
    }
    .history-time{
        font-size: 22px;
        margin-right: 5px;
        color:#FF761D;
    }
    .history-list{
        padding:10px;
        border:1px solid #FFE1CE;
        border-radius: 10px;
        margin-top:15px;
        line-height: 1.7
    }
    #update-history-list .ivu-modal-footer{
        border:0
    }
    .set-form td{
      padding:5px 10px  5px 0;
      cursor: pointer;
    }
</style>
