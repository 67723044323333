<template>
  <div class="da_box">
    <Tabs v-model="index">
      <TabPane label="基本设置" name="1"></TabPane>
      <TabPane label="会员价设置" name="2"></TabPane>
      <TabPane label="营销设置" name="3"></TabPane>
    </Tabs>
    <div v-if="index == 1" class="form_box">
      <Form label-position="left" :label-width="100">
        <FormItem label="排序：">
          <Input
            style="width: 400px"
            placeholder="请输入排序"
            v-model="formdata.sort"
          ></Input>
        </FormItem>
        <FormItem label="商品分类：">
          <Select
            @on-change="jiantin"
            v-model="formdata.cate_id"
            style="width: 400px"
          >
            <Option v-for="item in cateList" :value="item.id" :key="item.id">{{
              item.cate_name
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="二级分类：">
          <Select
            v-model="formdata.second_cate_id"
            clearable
            style="width: 400px"
          >
            <Option
              v-for="item in erjicateList"
              :value="item.id"
              :key="item.id"
              >{{ item.cate_name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="商品名称：">
          <Input
            style="width: 400px"
            placeholder="请输入商品名称"
            v-model="formdata.goods_name"
          ></Input>
          <Button
            v-if="!editType"
            @click="warehouseBtn"
            style="color: #ff761d; border: 1px solid #ff761d; margin-left: 20px"
            >商品库中选择</Button
          >
        </FormItem>
        <FormItem label="商品图片：">
          <div style="display: flex">
            <div v-if="picList" class="pic_hezi">
              <img :src="picList" alt="" />
            </div>
            <div>
              <!-- <Upload
                    ref="upload"
                    :show-upload-list="false"
                    :format="['jpg','jpeg','png']"
                    :max-size="2048"
                    :headers="header"
                    :on-format-error="handleFormatError"
                    :on-success="uploadImgSuccess"
                    :on-progress="handleProgress"
                    multiple
                    :action="uploads"> -->
              <div class="uplads_box" @click="openModal(1)">
                <div style="text-align: center">
                  <div style="margin-top: -10px">
                    <Icon style="color: #9ea6b9" size="70" type="ios-add" />
                  </div>
                  <div class="font14" style="margin-top: -10px; color: #9ca7b9">
                    点击上传
                  </div>
                  <div
                    style="font-size: 12px; color: #9ca7b9; margin-top: -15px"
                  >
                    （193*175）
                  </div>
                </div>
              </div>
              <!-- </Upload> -->
            </div>
          </div>
        </FormItem>
        <FormItem label="显示销量：">
          <i-switch
            v-model="formdata.show_salas"
            size="large"
            :true-value="1"
            :false-value="0"
          >
            <span slot="open">显示</span>
            <span slot="close">隐藏</span>
          </i-switch>
        </FormItem>
        <FormItem
          v-if="formdata.show_salas == 1"
          style="margin-left: 66px"
          label="虚拟销量："
        >
          <i-switch
            v-model="formdata.custom_salas_status"
            size="large"
            :true-value="1"
            :false-value="0"
          >
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <FormItem
          style="margin-left: 104px"
          v-if="formdata.show_salas == 1 && formdata.custom_salas_status == 1"
          label="销量："
        >
          <Input
            type="number"
            style="width: 400px"
            placeholder="请输入虚拟销量："
            v-model="formdata.custom_salas"
          ></Input>
        </FormItem>
        <FormItem label="商品单位：">
          <Input
            style="width: 400px"
            placeholder="请输入商品单位"
            v-model="formdata.goods_unit"
          ></Input>
        </FormItem>
        <FormItem label="启用库存：">
          <i-switch
            v-model="formdata.stock_status"
            size="large"
            :true-value="1"
            :false-value="0"
          >
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <FormItem label="显示库存：" v-if="formdata.stock_status == 1">
          <i-switch
            v-model="formdata.show_stock"
            size="large"
            :true-value="1"
            :false-value="0"
          >
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>

        <FormItem style="margin: 0" label="商品价格："></FormItem>
        <Form
          style="padding: 0 0 20px 100px"
          inline
          label-position="right"
          :label-width="70"
        >
          <div v-for="(item, index) of formdata.goods_prices" :key="index">
            <FormItem label="规格名：">
              <Input v-model="item.specs" style="width: 160px"></Input>
            </FormItem>
            <FormItem label="价格：">
              <Input
                v-model="item.price"
                type="number"
                style="width: 160px"
              ></Input>
            </FormItem>
            <FormItem label="原价：">
              <Input
                v-model="item.old_price"
                type="number"
                style="width: 160px"
              ></Input>
            </FormItem>
            <FormItem label="包装费：">
              <Input
                v-model="item.pack_money"
                type="number"
                style="width: 160px"
              ></Input>
            </FormItem>
            <FormItem label="库存：">
              <Input
                v-on:input="inputFunc($event, item)"
                type="number"
                v-model="item.stock"
                style="width: 160px"
              ></Input>
            </FormItem>
            <FormItem label="">
              <Checkbox v-model="item.is_full" :true-value="1" :false-value="0"
                >次日置满</Checkbox
              >
            </FormItem>
            <FormItem label="">
              <Button
                @click="clearPrice(index)"
                style="width: 60px; text-align: cemter"
                icon="md-remove"
              ></Button
              ><span style="margin-left: 10px"></span>
            </FormItem>
          </div>
          <div>
            <Button
              @click="addprice"
              style="width: 60px; text-align: cemter"
              icon="md-add"
            ></Button
            ><span style="margin-left: 10px">最多添加10个</span>
          </div>
        </Form>

        <FormItem label="商品属性："> </FormItem>
        <Form style="padding: 0 0 20px 100px" inline label-position="right">
          <div
            v-for="(item, index) of formdata.goods_attr"
            :key="index"
            style="margin-bottom: 20px"
          >
            <div class="just_center">
              <FormItem label="属性名称：" :label-width="90">
                <Input v-model="item.attr_name" style="width: 160px"></Input>
              </FormItem>

              <div>
                <FormItem label="请输入属性值：" :label-width="120">
                  <Input
                    v-for="(j, k) of item.values"
                    :key="k"
                    v-model="item.values[k]"
                    class="mr20"
                    style="width: 80px"
                  ></Input>
                  <Button
                    @click="attributevalueBtn(item)"
                    style="width: 60px; text-align: cemter"
                    icon="md-add"
                  ></Button
                  ><span style="margin-left: 10px"></span>
                  <Button
                    @click="clearItem(index)"
                    style="width: 60px; text-align: cemter"
                    icon="md-remove"
                  ></Button
                  ><span style="margin-left: 10px"></span>
                </FormItem>
              </div>
            </div>
            <div style="margin-left: 90px">
              <Button
                @click="clearshop(index)"
                style="width: 60px; text-align: cemter"
                icon="md-remove"
              ></Button
              ><span style="margin-left: 10px"></span>
            </div>
          </div>

          <div>
            <Button
              @click="commodityattribute"
              style="width: 60px; text-align: cemter"
              icon="md-add"
            ></Button
            ><span style="margin-left: 10px">最多添加10个</span>
          </div>
        </Form>

        <FormItem label="加料："></FormItem>
        <Form
          style="padding: 0 0 20px 100px"
          inline
          label-position="right"
          :label-width="70"
        >
          <div v-for="(item, index) of formdata.food" :key="index">
            <div>
              <FormItem :label="'料名' + item.key + '：'">
                <Input v-model="item.food_name" style="width: 160px"></Input>
              </FormItem>
              <FormItem label="价格：">
                <Input
                  v-model="item.price"
                  type="number"
                  style="width: 160px"
                ></Input>
              </FormItem>
              <FormItem label="库存：">
                <Input
                  v-on:input="inputFunc1($event, item)"
                  type="number"
                  v-model="item.stock"
                  style="width: 160px"
                ></Input>
              </FormItem>
              <FormItem label="">
                <Checkbox
                  v-model="item.is_full"
                  :true-value="1"
                  :false-value="0"
                  >次日置满</Checkbox
                >
              </FormItem>
            </div>

            <FormItem label="">
              <Button
                @click="clearFeeding(index)"
                style="width: 60px; text-align: cemter"
                icon="md-remove"
              ></Button
              ><span style="margin-left: 10px"></span>
            </FormItem>
          </div>
          <div>
            <Button
              @click="Feeding"
              style="width: 60px; text-align: cemter"
              icon="md-add"
            ></Button
            ><span style="margin-left: 10px">最多添加10个</span>
          </div>
        </Form>

        <FormItem label="商品标签：">
          <Input
            style="width: 400px"
            placeholder="请输入商品标签"
            v-model="formdata.label"
          ></Input>
        </FormItem>
        <FormItem label="模式：">
          <CheckboxGroup v-model="formdata.mode" class="fenbu">
            <Checkbox
              v-for="(item, index) of pattern"
              :key="index"
              style="margin-left: 15px"
              :label="item.id"
              >{{ item.mode_name }}</Checkbox
            >
          </CheckboxGroup>
        </FormItem>
        <FormItem label="商品简介：">
          <textarea
            v-model="formdata.desc"
            class="textar"
            name=""
            id=""
            cols="30"
            rows="10"
          ></textarea>
        </FormItem>
        <FormItem label="商品轮播：">
          <!-- <Upload
            ref="upload"
            :disabled="picList1.length >= 3 ? true : false"
            :show-upload-list="false"
            :format="['jpg', 'jpeg', 'png']"
            :max-size="2048"
            :headers="header"
            :on-format-error="handleFormatError"
            :on-success="uploadImgSuccess1"
            :on-progress="handleProgress"
            multiple
            :action="uploads"
          > -->
          <Button @click="openModal(3)">点击上传</Button>
          <span>（最多上传3张图 372*235）</span>
          <!-- </Upload> -->
          <div class="img_da_box">
            <div v-for="(item, index) of picList1" :key="index">
              <div class="img_box">
                <Icon
                  @click="closePic(index)"
                  size="20"
                  class="close_dw"
                  type="ios-close-circle"
                />
                <img :src="item" alt="" />
              </div>
            </div>
          </div>
        </FormItem>
      </Form>
      <!-- 图片库 -->
      <imageLibrary
        v-if="switch_modal"
        :switch_modal.sync="switch_modal"
        @change="costPlannedAmountChange"
        :select="imgtype"
      ></imageLibrary>
    </div>
    <div v-if="index == 2" class="form_box">
      <Form label-position="left" :label-width="160">
        <FormItem label="是否启用会员折扣：">
          <i-switch
            v-model="formdata.vip_status"
            size="large"
            :true-value="1"
            :false-value="0"
          >
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <FormItem v-if="formdata.vip_status == 1" label="是否单独设置会员价：">
          <i-switch
            v-model="formdata.is_vip_alone"
            size="large"
            :true-value="1"
            :false-value="0"
          >
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <div v-if="formdata.vip_status == 1 && formdata.is_vip_alone == 1">
          <FormItem style="margin: 0" label="单独会员价设置："></FormItem>
          <Form
            style="padding: 10px 0 20px 160px"
            inline
            label-position="right"
            :label-width="70"
          >
            <div v-for="(item, index) of formdata.goods_prices" :key="index">
              <FormItem label="规格名：">
                <!-- <Input v-model="item.specs" style="width: 160px"></Input> -->
                <div style="width: 100px">{{ item.specs }}</div>
              </FormItem>
              <FormItem label="会员价：">
                <Input
                  v-model="item.vip_price"
                  type="number"
                  style="width: 160px"
                ></Input>
              </FormItem>
            </div>
          </Form>
        </div>
      </Form>
    </div>
    <div v-if="index == 3" class="form_box">
      <Form label-position="left" :label-width="80">
        <FormItem label="限购功能">
          <i-switch
            v-model="formdata.limit_status"
            size="large"
            :true-value="1"
            :false-value="0"
          >
            <span slot="open">开启</span>
            <span slot="close">关闭</span>
          </i-switch>
        </FormItem>
        <div v-if="formdata.limit_status == 1" style="margin-left: 50px">
          每个用户每
          <Input
            type="number"
            v-model="formdata.limit_day"
            style="width: 100px; padding: 0 5px"
          ></Input>
          天，限购
          <Input
            type="number"
            v-model="formdata.limit_num"
            style="width: 100px; padding: 0 5px"
          ></Input>
          件
        </div>
        <!-- <FormItem label="限制">
                    每个用户<Input></Input>天，限购<Input></Input>件
                </FormItem> -->
      </Form>
    </div>
    <div class="bottom_btn">
      <Button @click="barck">取消</Button>
      <Button @click="baocunBtn" class="btn_A">保存</Button>
    </div>

    <!-- 商品库弹框 -->
    <Modal
      v-model="warehousemodal"
      :mask-closable="false"
      title="商品库选择商品"
      @on-cancel="warehouseCancel"
    >
      <div>
        <Form label-position="left" :label-width="100">
          <FormItem label="商品名称：">
            <Select
              @on-change="warehouseChange"
              v-model="shopName"
              filterable
              :remote-method="remoteMethod"
              :loading="loading"
            >
              <Option
                v-for="(option, index) in shopList"
                :value="option.goods_name"
                :key="index"
                >{{ option.goods_name }}</Option
              >
            </Select>
          </FormItem>
        </Form>
        <div class="tishiTxt"></div>
      </div>
      <div slot="footer">
        <Button @click="warehouseCancel">取消</Button>
      </div>
    </Modal>

    <!-- 查看商品弹框 -->
    <Modal
      v-model="shopmodal"
      :mask-closable="false"
      title="查看商品"
      @on-cancel="shopCancel"
    >
      <div>
        <Form label-position="left" :label-width="100">
          <FormItem label="商品名称：">
            <Input
              disabled
              v-model="shopData.goods_name"
              style="width: 350px"
            ></Input>
          </FormItem>
          <FormItem label="商品图片：">
            <div class="pic_hezi"><img :src="shopData.goods_img" alt="" /></div>
          </FormItem>
          <FormItem label="商品单位：">
            <Input
              disabled
              v-model="shopData.goods_unit"
              style="width: 350px"
            ></Input>
          </FormItem>
          <FormItem style="margin: 0" label="商品价格："></FormItem>
          <Form
            style="padding: 0 0 20px 100px"
            inline
            label-position="right"
            :label-width="70"
          >
            <div
              style="border-bottom: 1px solid #eee; padding-top: 20px"
              v-for="(item, index) of shopData.goods_prices"
              :key="index"
            >
              <FormItem label="规格名：">
                <Input
                  disabled
                  v-model="item.specs"
                  style="width: 100px"
                ></Input>
              </FormItem>
              <FormItem label="价格：">
                <Input
                  disabled
                  v-model="item.price"
                  type="number"
                  style="width: 100px"
                ></Input>
              </FormItem>
              <FormItem label="原价：">
                <Input
                  disabled
                  v-model="item.old_price"
                  type="number"
                  style="width: 100px"
                ></Input>
              </FormItem>
              <FormItem label="包装费：">
                <Input
                  disabled
                  v-model="item.pack_money"
                  type="number"
                  style="width: 100px"
                ></Input>
              </FormItem>
              <FormItem label="库存：">
                <Input
                  disabled
                  v-on:input="inputFunc($event, item)"
                  type="number"
                  v-model="item.stock"
                  style="width: 100px"
                ></Input>
              </FormItem>
              <FormItem label="">
                <Checkbox
                  disabled
                  v-model="item.is_full"
                  :true-value="1"
                  :false-value="0"
                  >次日置满</Checkbox
                >
              </FormItem>
            </div>
          </Form>
          <FormItem style="margin: 0" label="商品属性："></FormItem>
          <Form
            style="padding: 0 0 20px 100px"
            inline
            label-position="right"
            :label-width="90"
          >
            <div
              style="border-bottom: 1px solid #eee; padding-top: 20px"
              v-for="(item, index) of shopData.goods_attr"
              :key="index"
            >
              <FormItem label="属性名称：">
                <Input
                  disabled
                  v-model="item.attr_name"
                  style="width: 260px"
                ></Input>
              </FormItem>
              <div>
                <FormItem label="属性值：">
                  <div
                    style="
                      width: 260px;
                      display: flex;
                      flex-wrap: wrap;
                      justify-content: space-between;
                    "
                  >
                    <Input
                      disabled
                      v-for="(j, k) of item.values"
                      :key="k"
                      v-model="item.values[k]"
                      style="width: 100px; margin-bottom: 10px"
                    ></Input>
                  </div>
                </FormItem>
              </div>
            </div>
          </Form>
          <FormItem style="margin: 0" label="加料："></FormItem>
          <Form
            style="padding: 0 0 20px 100px"
            inline
            label-position="right"
            :label-width="70"
          >
            <div
              style="border-bottom: 1px solid #eee; padding-top: 20px"
              v-for="(item, index) of shopData.food"
              :key="index"
            >
              <FormItem label="料名：">
                <Input
                  disabled
                  v-model="item.food_name"
                  style="width: 100px"
                ></Input>
              </FormItem>
              <FormItem label="价格：">
                <Input
                  disabled
                  v-model="item.price"
                  type="number"
                  style="width: 100px"
                ></Input>
              </FormItem>
              <FormItem label="库存：">
                <Input
                  disabled
                  v-on:input="inputFunc1($event, item)"
                  type="number"
                  v-model="item.stock"
                  style="width: 100px"
                ></Input>
              </FormItem>
              <FormItem label="">
                <Checkbox
                  disabled
                  v-model="item.is_full"
                  :true-value="1"
                  :false-value="0"
                  >次日置满</Checkbox
                >
              </FormItem>
            </div>
          </Form>
          <FormItem label="商品标签：">
            <Input
              disabled
              v-model="shopData.label"
              style="width: 350px"
            ></Input>
          </FormItem>
          <FormItem label="模式：">
            <CheckboxGroup v-model="shopData.mode" class="fenbu">
              <Checkbox
                disabled
                v-for="(item, index) of pattern"
                :key="index"
                style="margin-left: 15px"
                :label="item.id"
                >{{ item.mode_name }}</Checkbox
              >
            </CheckboxGroup>
          </FormItem>
          <FormItem label="商品简介：">
            <textarea
              disabled
              v-model="shopData.desc"
              style="min-width: 350px; max-width: 350px"
              class="textar"
              name=""
              id=""
              cols="30"
              rows="10"
            ></textarea>
          </FormItem>
          <FormItem label="商品轮播：">
            <div
              style="
                width: 350px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
              "
            >
              <img
                style="
                  width: 160px;
                  height: 90px;
                  object-fit: cover;
                  border-radius: 5px;
                  margin-bottom: 10px;
                "
                v-for="(item, index) of shopData.banner"
                :key="index"
                :src="item"
                alt=""
              />
            </div>
          </FormItem>
        </Form>
      </div>
      <div slot="footer">
        <Button @click="shopCancel">取消</Button>
        <Button class="btn_A" @click="shopOk">使用</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  getCate,
  addGoods,
  getMode,
  goodsDetail,
  updateGoods,
  setGoodsVipConf,
  goodsLimit,
  goodsQuery,
  getSecondLevel,
} from "@/api/customer";
import config from "@/config";
import { getCookies } from "@/utils/helper";
import imageLibrary from "@/components/diy/imageLibrary.vue";
import { connect } from "echarts";
export default {
  components: { imageLibrary },
  name: "addShop",
  props: { titleSync: Object },
  data() {
    return {
      imgtype: 1,
      switch_modal: false,
        header: {
          "hlzw-canyin-authori-zation": getCookies("hlzw_canyin_token"),
        },
      pattern: [],
        uploads: config.apiUrl + "/storeapi/data/uploads",
      picList: "",
      picList1: [],
      index: "1",
      formdata: {
        goods_name: "",
        sort: "",
        vip_status: 0,
        is_vip_alone: 0,
        custom_salas_status: 0,
        show_salas: 0,
        stock_status: 1,
        show_stock:0,
        second_cate_id: 0,
        custom_salas: 0,
        goods_img: "",
        goods_unit: "",
        goods_prices: [],
        goods_attr: [],
        food: [],
        label: "",
        mode: [],
        desc: "",
        banner: [],
        cate_id: "",
        limit_status: "",
        limit_num: "",
        limit_day: "",
      },
      cateList: [],
      erjicateList: [],
      shopList: [],
      shopName: "",
      shopData: {},
      loading: false,
      shopmodal: false,
      warehousemodal: false,
      editType: false,
    };
  },
  created() {
    if (this.titleSync.id) {
      this.editType = true;
      this.goodsDetail();
    }
    this.getCate();
    this.getMode();
  },
  methods: {
    // 事件处理函数
    async costPlannedAmountChange(param1, param2) {
      if (this.imgtype == 1) {
        for (var i = 0; i < param1.length; i++) {
          this.picList = param1[i];
        }
        for (var i = 0; i < param2.length; i++) {
          this.formdata.goods_img = param2[i];
        }
        this.$Message.destroy();
      } else {
        if (this.picList1.length < 3) {
          for (var i = 0; i < param1.length; i++) {
            this.picList1.push(param1[i]);
          }
          for (var i = 0; i < param2.length; i++) {
            this.formdata.banner.push(param2[i]);
          }
          this.$Message.destroy();
        }
      }
    },
    openModal(e) {
      this.imgtype = e;
      this.switch_modal = true;
      if (e == 2 && this.picList1.length >= 3) {
        this.switch_modal = false;
      }
    },
    barck() {
      this.$emit("titleSync", false);
    },
    goodsDetail() {
      goodsDetail({ id: this.titleSync.id }).then((res) => {
        let arr = res.data.mode;
        this.picList = res.data.goods_img_domain;
        this.picList1 = res.data.banner_domain;

        this.formdata.id = res.data.id;
        this.formdata.custom_salas_status = res.data.custom_salas_status;
        this.formdata.show_salas = res.data.show_salas;
        this.formdata.stock_status = res.data.stock_status;
        this.formdata.show_stock = res.data.show_stock;
        this.formdata.custom_salas = res.data.custom_salas;
        this.formdata.goods_name = res.data.goods_name;
        this.formdata.sort = res.data.sort;
        this.formdata.goods_img = res.data.goods_img;
        this.formdata.goods_unit = res.data.goods_unit;
        this.formdata.goods_prices = res.data.goods_prices;
        this.formdata.goods_attr = res.data.goods_attr;
        this.formdata.vip_status = res.data.vip_status;
        this.formdata.is_vip_alone = res.data.is_vip_alone;
        this.formdata.food = res.data.food;
        this.formdata.label = res.data.label;
        this.formdata.mode = arr.map(Number);
        this.formdata.desc = res.data.desc;
        this.formdata.banner = res.data.banner;
        this.formdata.cate_id = res.data.cate_id;
        this.formdata.second_cate_id = res.data.second_cate_id;
        if (this.formdata.cate_id) {
          getSecondLevel({ id: this.formdata.cate_id }).then((res) => {
            this.erjicateList = res.data;
          });
        }
        this.formdata.limit_status = res.data.limit_status;
        this.formdata.limit_num = res.data.limit_num;
        this.formdata.limit_day = res.data.limit_day;
      });
    },
    getMode() {
      getMode().then((res) => {
        this.pattern = res.data;
        if (!this.titleSync.id) {
          this.pattern.forEach((item) => {
            this.formdata.mode.push(item.id);
          });
        }
      });
    },
    getCate() {
      getCate().then((res) => {
        this.cateList = res.data;
      });
    },
    closePic(e) {
      this.formdata.banner.splice(e, 1);
      this.picList1.splice(e, 1);
    },
    addprice() {
      if (this.formdata.goods_prices.length < 10) {
        this.formdata.goods_prices.push({
          key:
            this.formdata.goods_prices.length == 0
              ? 1
              : this.formdata.goods_prices[
                  this.formdata.goods_prices.length - 1
                ].key + 1,
          specs: "默认",
          old_price: "0",
          price: "0",
          vip_price: "0",
          pack_money: "0",
          stock: "999",
          stock_full: "999",
          is_full: 0,
        });
      } else {
        this.$Message.warning("最多添加10个");
      }
    },
    jiantin(e) {
      this.formdata.second_cate_id = 0;
      getSecondLevel({ id: e }).then((res) => {
        this.erjicateList = res.data;
      });
    },
    // 商品库按钮
    warehouseBtn() {
      this.warehousemodal = true;
    },
    // 商品库确定按钮
    warehouseOk() {},
    // 商品库取消按钮
    warehouseCancel() {
      this.shopName = "";
      this.warehousemodal = false;
    },
    // 商品库select监听
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        setTimeout(() => {
          goodsQuery({ goods_name: query })
            .then((res) => {
              this.loading = false;
              this.shopList = res.data;
            })
            .catch((err) => {
              this.loading = false;
            });
        }, 200);
      } else {
        this.shopList = [];
      }
    },
    // 商品库监听事件
    warehouseChange(e) {
      this.shopList.forEach((item) => {
        if (e == item.goods_name) {
          this.shopData = item;
        }
      });
      if (this.shopName) {
        this.shopmodal = true;
      }
    },
    // 查看商品取消按钮
    shopCancel() {
      this.shopName = "";
      this.shopmodal = false;
    },
    // 查看商品确定按钮
    shopOk() {
      this.picList = JSON.parse(JSON.stringify(this.shopData.goods_img));
      this.picList1 = JSON.parse(JSON.stringify(this.shopData.banner));
      this.formdata.goods_img = JSON.parse(
        JSON.stringify(this.shopData.goods_img)
      );
      this.formdata.banner = JSON.parse(JSON.stringify(this.shopData.banner));
      this.formdata.desc = this.shopData.desc;
      this.formdata.food = this.shopData.food;
      this.formdata.goods_attr = this.shopData.goods_attr;
      this.formdata.goods_name = this.shopData.goods_name;
      this.formdata.goods_prices = this.shopData.goods_prices;
      this.formdata.goods_unit = this.shopData.goods_unit;
      this.formdata.label = this.shopData.label;
      this.shopCancel();
      this.warehouseCancel();
    },
    commodityattribute() {
      if (this.formdata.goods_attr.length < 10) {
        this.formdata.goods_attr.push({ attr_name: "默认", values: [""] });
      } else {
        this.$Message.warning("最多添加10个");
      }
    },
    Feeding() {
      if (this.formdata.food.length < 10) {
        this.formdata.food.push({
          key:
            this.formdata.food.length == 0
              ? 1
              : this.formdata.food[this.formdata.food.length - 1].key + 1,
          food_name: "默认",
          price: "0",
          stock: "999",
          stock_full: "999",
          is_full: 0,
        });
      } else {
        this.$Message.warning("最多添加10个");
      }
    },
    attributevalueBtn(e) {
      e.values.push("");
    },
    inputFunc(e, item) {
      item.stock_full = e;
    },
    inputFunc1(e, item) {
      item.stock_full = e;
    },
    baocunBtn() {
        if (this.formdata.goods_img.indexOf("https") >= 0) {
            const value1 = this.formdata.goods_img.slice(41);//32
            if(value1.length == 32){
                this.formdata.goods_img = value1;
            }else{
                const value3 = this.formdata.goods_img.slice(31);
                this.formdata.goods_img = value3;
            }
            let numbers = this.formdata.banner.map((name) =>
                name.replace("https://oss.hulianzaowu.top/api/img/show/", "")
            );
            this.formdata.banner = numbers;
        }
        if (this.formdata.goods_img.indexOf("http") >= 0) {
            const value2 = this.formdata.goods_img.slice(39);
            this.formdata.goods_img = value2;
            let number = this.formdata.banner.map((name) =>
                name.replace("http://192.168.1.199:9091/api/img/show/", "")
            );
            this.formdata.banner = number;
        }
      if (this.index == 1) {
        if (this.titleSync.type == 1) {
          addGoods(this.formdata)
            .then((res) => {
              this.$emit("titleSync", false);
              this.$Message.success(res.msg);
            })
            .catch((err) => {
              this.$Message.error(err.msg);
            });
        } else {
          updateGoods(this.formdata)
            .then((res) => {
              this.$emit("titleSync", false);
              this.$Message.success(res.msg);
            })
            .catch((err) => {
              this.$Message.error(err.msg);
            });
        }
      } else if (this.index == 2) {
        let data = {
          id: this.formdata.id,
          goods_prices: this.formdata.goods_prices,
          vip_status: this.formdata.vip_status,
          is_vip_alone: this.formdata.is_vip_alone,
        };
        console.log("1028", data);
        setGoodsVipConf(data)
          .then((res) => {
            this.$Message.success(res.msg);
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      } else if (this.index == 3) {
        let data = {
          id: this.formdata.id,
          limit_status: this.formdata.limit_status,
          limit_day: this.formdata.limit_day,
          limit_num: this.formdata.limit_num,
        };
        goodsLimit(data)
          .then((res) => {
            this.$Message.success(res.msg);
          })
          .catch((err) => {
            this.$Message.error(err.msg);
          });
      }
    },
    clearPrice(e) {
      this.formdata.goods_prices.splice(e, 1);
    },
    clearFeeding(e) {
      this.formdata.food.splice(e, 1);
    },
    clearshop(e) {
      this.formdata.goods_attr.splice(e, 1);
    },
    clearItem(e) {
      if (this.formdata.goods_attr[e].values.length != 1) {
        this.formdata.goods_attr[e].values.splice(
          this.formdata.goods_attr[e].values.length - 1,
          1
        );
      }
    },
    //文件上传类型错误
    handleFormatError() {
      this.$Message.warning("暂不支持上传此格式");
    },
    //编辑图片上传图片成功
    uploadImgSuccess(e) {
      this.picList = e.data.path;
      this.formdata.goods_img = e.data.src;
      // this.formValidate.logo = e.data
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    uploadImgSuccess1(e) {
      this.picList1.push(e.data.path);
      this.formdata.banner.push(e.data.src);
      this.$Message.destroy();
      this.$Message.success(e.msg);
    },
    // 文件上传时
    handleProgress() {
      this.$Message.loading({
        content: "上传中...",
        duration: 0,
      });
    },
  },
};
</script>

<style scoped>
/* .da_box{
    position: absolute;
    top: 84px;
    right: 20px;
    width: calc(100% - 250px);
    box-sizing: border-box;
    height: calc(100% - 84px);
    z-index: 9;
    border-radius: 5px;
    background: #000;
} */
.da_box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  height: 88vh;
  z-index: 5;
  /* background: #F5F7F9; */
  background: #fff;
}
.bottom_btn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 100px;
  border-top: 1px solid #eee;
  background: #fff;
}
.btn_A {
  background: #ff761d;
  color: #fff;
  margin-left: 20px;
}
.form_box {
  padding: 30px 40px;
  /* height: 82vh; */
  height: 76vh;
  overflow: auto;
}
.textar {
  max-width: 400px;
  min-width: 400px;
  max-height: 100px;
  min-height: 100px;
  outline: #57a3f3;
  border: 1px solid #dcdee2;
}
.just_center {
  width: 100%;
  display: flex;
  /* align-items: center; */
}
.uplads_box {
  width: 105px;
  height: 105px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #a0a4bd;
  background: #f7f8fa;
  border-radius: 5px;
  cursor: pointer;
}
.img_da_box {
  display: flex;
  align-items: center;
}
.img_box {
  position: relative;
  margin-top: 10px;
  width: 200px;
  height: 100px;
  margin-right: 20px;
  /* overflow: hidden; */
}
.img_box img {
  width: 100%;
  border-radius: 5px;
  height: 100%;
  object-fit: cover;
}
.attributBox {
  display: flex;
  flex-wrap: wrap;
}
.close_dw {
  position: absolute;
  top: -7px;
  right: -7px;
}
.mr20 {
  margin-right: 20px;
}
.pic_hezi {
  width: 105px;
  height: 105px;
  border: 1px solid #999;
  overflow: hidden;
  border-radius: 5px;
  margin-right: 20px;
}
.pic_hezi img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.tishiTxt {
  text-align: center;
  margin-top: 100px;
  font-size: 14px;
  color: red;
}
</style>
<style>
.ivu-checkbox-checked .ivu-checkbox-inner {
  border-color: #ff761d;
  background-color: #ff761d;
}
.ivu-form-label-left .ivu-form-item-label {
  text-align: right;
}
.ivu-switch-checked {
  border-color: #ff761d;
  background-color: #ff761d;
}
</style>
